import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useFormik } from "formik";
import api from "../../../services/api";

import { CircularProgress } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";

import { Input, Select, Typography } from "../../../components";

import BackButton from "../../../components/BackButton";
import Button from "../../../components/Button";

import { AuthContext } from "../../../contexts/auth";
import { QuestionContext } from "../../../contexts/question";
import { ERoutesPath } from "../../../routes";

import {
  initialFormData,
  optionsWeight,
  optionsDifficulty,
  optionsCorrectQuestion,
} from "./helper";

import * as S from "./styles";
import { EApiPath, EApiUploadImage } from "./types";

export const QuestionManagement: React.FC = () => {
  const navigate = useNavigate();

  const { typeUser } = useContext(AuthContext);
  const { selectedQuestion, setSelectedQuestion } = useContext(QuestionContext);
  const isEdition = !!selectedQuestion;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (typeUser !== "10") {
      navigate(ERoutesPath.ROOT);
      return;
    }
    scroll(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: isEdition
      ? { ...selectedQuestion, flash_card: selectedQuestion.flashcard }
      : initialFormData,
    onSubmit: () => handleAddQuestion(),
  });

  const isFlashCard = useMemo(() => {
    return formik.values.flash_card;
  }, [formik.values.flash_card]);

  const uploadIfChanged = async (
    idQuestion: number | string,
    fieldName: keyof typeof formik.values,
    apiType: EApiUploadImage
  ) => {
    if (isEdition && selectedQuestion[fieldName] === formik.values[fieldName]) {
      return;
    } else {
      let image = Object(formik.values[fieldName]);
      let formImage = new FormData();
      formImage.append("imageQuestion", image);
      formImage.append("questionID", String(idQuestion));
      await apiUploadImage(formImage, apiType);
    }
  };

  const handleAddQuestion = async () => {
    if (
      formik.values.difficulty === "" ||
      formik.values.weight === "" ||
      formik.values.feedback === ""
    ) {
      alert(
        "É necessário realizar a selação dos campos dificuldade, peso da questão e gabarito."
      );
      return;
    } else {
      setIsLoading(true);
      try {
        const objectSend = {
          ...formik.values,
          office: "",
          level: "",
        };

        if (isEdition) {
          const question = await api.put(EApiPath.UPDATE_QUESTION, objectSend);
          const idQuestion = question.data.id;

          await Promise.all([
            uploadIfChanged(
              idQuestion,
              "description_issue",
              EApiUploadImage.UPDATE_IMAGE_DESCRIPTION
            ),
            uploadIfChanged(
              idQuestion,
              "alternatives_image",
              EApiUploadImage.UPDATE_IMAGE_ALTERNATIVES
            ),
            uploadIfChanged(idQuestion, "image", EApiUploadImage.UPDATE_IMAGE),
          ]);
        } else {
          const question = await api.post(EApiPath.ADD_QUESTION, objectSend);
          const idQuestion = question.data.id;

          await Promise.all([
            uploadIfChanged(idQuestion, "image", EApiUploadImage.ADD_IMAGE),
            uploadIfChanged(
              idQuestion,
              "description_issue",
              EApiUploadImage.ADD_IMAGE_DESCRIPTION
            ),
            uploadIfChanged(
              idQuestion,
              "alternatives_image",
              EApiUploadImage.ADD_IMAGE_ALTERNATIVES
            ),
          ]);
        }

        alert(
          "Questão " + (isEdition ? "editada" : "cadastrada") + " com sucesso!"
        );
        formik.resetForm();
      } catch (error) {
        alert(error.response);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const apiUploadImage = async (imageData: any, imageUrl: EApiUploadImage) => {
    try {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      await api.post(imageUrl, imageData, options);
      alert(
        "A imagem pode demorar até 1 minuto para ser cadastrada, então não precisa ficar preocupado caso ela não apareça de imediato. 😄 "
      );
    } catch (e) {
      alert(
        "Desculpe. Não foi possível cadastrar a imagem da questão, tente novamente mais tarde..."
      );
    }
  };

  const handleGoBack = () => {
    if (selectedQuestion) setSelectedQuestion(undefined);
    navigate(
      isEdition ? ERoutesPath.QUESTOES : ERoutesPath.INICIO_PROFISSIONAL
    );
  };

  return (
    <>
      <S.Container>
        <S.FlexWrapper>
          <Typography variant="title">
            {isEdition ? "Atualizar questão" : "Cadastrar questão"}
          </Typography>
          <BackButton type="button" onClick={handleGoBack}>
            Voltar
          </BackButton>
        </S.FlexWrapper>

        <S.StyledForm onSubmit={formik.handleSubmit} noValidate>
          <Typography style={{ marginTop: "10px" }}>Dados Básicos</Typography>

          <S.Inline>
            <S.CheckboxContainer>
              <Checkbox
                name="flash_card"
                checked={formik.values.flash_card}
                onChange={(e) => {
                  formik.setFieldValue("flash_card", e.target.checked);
                  if (e.target.checked) {
                    formik.setFieldValue(
                      "alternative_c",
                      initialFormData.alternative_c
                    );
                    formik.setFieldValue(
                      "alternative_d",
                      initialFormData.alternative_d
                    );
                    formik.setFieldValue(
                      "alternative_e",
                      initialFormData.alternative_e
                    );
                    formik.setFieldValue("year", initialFormData.year);
                  } else {
                    formik.setFieldValue(
                      "description",
                      initialFormData.description
                    );
                  }
                }}
              />
              Questão revise-rápido
            </S.CheckboxContainer>

            {!!isFlashCard && (
              <Input
                type="text"
                name="description"
                placeholder="Descrição revise-rápido *"
                onChange={formik.handleChange}
                value={formik.values.description}
                color="gray"
              />
            )}
          </S.Inline>

          <S.ColumnContainer>
            <Input
              type="text"
              name="course"
              placeholder="Disciplina *"
              onChange={formik.handleChange}
              value={formik.values.course}
              autoCapitalize="words"
              color="gray"
            />
            <Input
              type="text"
              name="matter"
              placeholder="Assunto *"
              onChange={formik.handleChange}
              value={formik.values.matter}
              autoCapitalize="words"
              color="gray"
            />
            <Input
              type="text"
              name="bank"
              placeholder="Banca *"
              onChange={formik.handleChange}
              value={formik.values.bank}
              autoCapitalize="words"
              color="gray"
            />
            <Input
              type="text"
              name="institution"
              placeholder="Instituição *"
              onChange={formik.handleChange}
              value={formik.values.institution}
              autoCapitalize="words"
              color="gray"
            />
            <Select
              placeholder="Dificuldade *"
              {...formik.getFieldProps("difficulty")}
              options={optionsDifficulty}
              onChange={(e) => {
                formik.setFieldValue("difficulty", e.target.value);
              }}
              required
            />
            <Select
              placeholder="Peso da questão *"
              {...formik.getFieldProps("weight")}
              options={optionsWeight}
              onChange={(e) => {
                formik.setFieldValue("weight", e.target.value);
              }}
              required
            />
            {!isFlashCard && (
              <Input
                type="text"
                name="year"
                placeholder="Ano *"
                onChange={formik.handleChange}
                value={formik.values.year}
                color="gray"
              />
            )}
          </S.ColumnContainer>

          <Typography style={{ marginTop: "10px" }}>
            Anexos e alternativas
          </Typography>

          <S.ColumnContainer>
            <div>
              <Typography variant="small">
                Descrição da questão - Imagem
              </Typography>
              <Input
                name="description_issue"
                type="file"
                placeholder="Descrição da questão"
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "description_issue",
                    event.currentTarget.files[0]
                  );
                }}
                color="gray"
              />
            </div>

            <div>
              <Typography variant="small">
                Alternativas da questão - Imagem
              </Typography>
              <Input
                name="alternatives_image"
                type="file"
                placeholder="Alternativas"
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "alternatives_image",
                    event.currentTarget.files[0]
                  );
                }}
                color="gray"
              />
            </div>
          </S.ColumnContainer>

          <S.ColumnContainer columns={1} style={{ marginTop: "16px" }}>
            <Input
              name="alternative_a"
              type="text"
              placeholder="Alternativa A"
              onChange={formik.handleChange}
              value={formik.values.alternative_a}
              color="gray"
            />
            <Input
              name="alternative_b"
              type="text"
              placeholder="Alternativa B"
              onChange={formik.handleChange}
              value={formik.values.alternative_b}
              color="gray"
            />

            {!isFlashCard && (
              <>
                <Input
                  name="alternative_c"
                  type="text"
                  placeholder="Alternativa C"
                  onChange={formik.handleChange}
                  value={formik.values.alternative_c}
                  color="gray"
                />
                <Input
                  name="alternative_d"
                  type="text"
                  placeholder="Alternativa D"
                  onChange={formik.handleChange}
                  value={formik.values.alternative_d}
                  color="gray"
                />
                <Input
                  name="alternative_e"
                  type="text"
                  placeholder="Alternativa E"
                  onChange={formik.handleChange}
                  value={formik.values.alternative_e}
                  color="gray"
                />
              </>
            )}
          </S.ColumnContainer>

          <S.ContainerRow>
            <div style={{ width: "200px" }}>
              <Typography style={{ marginTop: "10px" }}>Resposta</Typography>
              <Select
                placeholder="Gabarito"
                {...formik.getFieldProps("feedback")}
                options={
                  isFlashCard
                    ? optionsCorrectQuestion.slice(0, 2)
                    : optionsCorrectQuestion
                }
                onChange={(e) => {
                  formik.setFieldValue("feedback", e.target.value);
                }}
                required
              />
            </div>

            <div style={{ width: "inherit" }}>
              <Typography variant="small" style={{ marginTop: "16px" }}>
                Correção da questão - Imagem
              </Typography>
              <Input
                name="image"
                type="file"
                placeholder="Imagem da resoluação"
                onChange={(event: any) => {
                  formik.setFieldValue("image", event.currentTarget.files[0]);
                }}
                color="gray"
              />
            </div>
          </S.ContainerRow>

          <Input
            name="comment_correction"
            type="text"
            placeholder="Explicação da opção correta *"
            onChange={formik.handleChange}
            value={formik.values.comment_correction}
            color="gray"
          />

          {isLoading ? (
            <CircularProgress />
          ) : (
            <S.ContainerButton>
              <Button type="submit">
                {isEdition ? "Atualizar questão" : "Cadastrar questão"}
              </Button>
            </S.ContainerButton>
          )}
        </S.StyledForm>
      </S.Container>
    </>
  );
};
