import { ERoutesPath } from "./routesPath";
import {
  HomePage,
  QuestionsPage,
  ProfessionalPage,
  QuestionManagement,
  SubjectsStudiedPage,
  ClientsPage,
  RevisionsPage,
  RevisionQuestionsPage,
  RevisionGeneralPage,
  CorrectQuestionsPage,
  FlashCardPage,
} from "../pages";

export const ROUTES = [
  { path: ERoutesPath.ROOT, element: HomePage },
  { path: ERoutesPath.QUESTOES, element: QuestionsPage },
  { path: ERoutesPath.INICIO_PROFISSIONAL, element: ProfessionalPage },
  { path: ERoutesPath.ADMINISTRAR_QUESTOES, element: QuestionManagement },
  { path: ERoutesPath.ASSUNTOS_ESTUDADOS, element: SubjectsStudiedPage },
  { path: ERoutesPath.USUARIOS, element: ClientsPage },
  { path: ERoutesPath.REVISOES, element: RevisionsPage },
  { path: ERoutesPath.REVISAO, element: RevisionQuestionsPage },
  { path: ERoutesPath.REVISAO_GERAL, element: RevisionGeneralPage },
  { path: ERoutesPath.CORRECAO, element: CorrectQuestionsPage },

  { path: ERoutesPath.REVISE_RAPIDO, element: FlashCardPage },
];
